import { useEffect, useState } from 'react';

import { Maybe } from '@house-booking/ui-kit';
import { PreBookingPriceCalculation, sdk } from '@shared/api';

export const useBookingHousePrice = (
  params: PreBookingPriceCalculation | null,
) => {
  const [price, setPrice] = useState<number>(0);
  const [withoutDiscountPrice, setWithoutDiscountPrice] =
    useState<Maybe<number>>(null);
  const [calculatePrice, { data, isLoading }] =
    sdk.useCalculateBookingPriceBookingCalculateBookingPricePostMutation();

  useEffect(() => {
    if (params) {
      calculatePrice({
        preBookingPriceCalculation: params,
      });
    }
  }, [params]);

  useEffect(() => {
    if (data?.total_price) {
      setPrice(data.total_price);
      if (
        data?.price_without_discount &&
        data?.price_without_discount >= data.total_price
      ) {
        setWithoutDiscountPrice(data.price_without_discount);
      }
    }
  }, [data]);

  return {
    isLoading,
    price,
    withoutDiscountPrice,
  };
};
