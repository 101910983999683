import { FC } from 'react';
import { Link } from 'react-justanother-router';

import { RouterName } from '@app/router';
import { SignInForm } from '@entities/Auth';
import { AuthFormWrapper } from '@features/Auth';
import { useAuth } from '@features/Auth/libs';
import { Typography } from '@house-booking/ui-kit';

export const AuthLogin: FC = (): JSX.Element => {
  const { error, loading, setData, setError } = useAuth();

  return (
    <AuthFormWrapper
      title="Авторизация"
      formSlot={
        <SignInForm
          onSubmit={setData}
          loading={loading}
          errors={error}
          setError={setError}
          disclaimerSlot={
            <Typography variant="head-14">
              Если нет аккаунта, то{' '}
              <Link to={RouterName.Register}>зарегистрируйтесь</Link>
            </Typography>
          }
        />
      }
    />
  );
};
