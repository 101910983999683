import { useEffect, useState } from 'react';

import { useAppDispatch } from '@app/store';
import { apiClient, BookingStatusEnum, sdk, TagTypes } from '@shared/api';

export const useCheckPaymentBooking = (id: number) => {
  const dispatch = useAppDispatch();
  const [isPending, setIsPending] = useState<boolean>(false);
  const { data } = sdk.useGetBookingByIdBookingGetIdGetQuery(
    {
      id,
    },
    { pollingInterval: isPending ? 3000 : undefined },
  );

  useEffect(() => {
    if (data) {
      const awaitPay = data.status === BookingStatusEnum.Pending;

      setIsPending(awaitPay);
      if (!awaitPay) {
        // @ts-ignore
        dispatch(apiClient.util.invalidateTags([{ type: TagTypes.Booking }]));
      }
    }
  }, [data]);
};
