import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-justanother-router';

import { renderCellDate } from '@/shared';
import { RouterName } from '@app/router';
import {
  CheckIcon,
  CloseIcon,
  LoaderOverlay,
  TableController,
} from '@house-booking/ui-kit';
import {
  ListUsersWithFiltersAndPaginationUserListGetApiArg,
  sdk,
  UserResponse,
} from '@shared/api';
import { pagination } from '@shared/libs';

const headers = [
  { key: true, title: 'ID', value: 'id', width: 60 },
  { title: 'Дата создания', value: 'createdAt', width: 160 },
  { title: 'Email', value: 'email' },
  { title: 'ФИО', value: 'fio' },
  { title: 'Телефон', value: 'phone' },
  { title: 'Роль', value: 'role' },
  { title: 'Статус', value: 'status' },
];

export type AdminUsersFilters = Omit<
  ListUsersWithFiltersAndPaginationUserListGetApiArg,
  'pagerSize' | 'pagerOffset'
>;
export interface AdminUsersTableProps {
  filters: AdminUsersFilters;
}
export const AdminUsersTable: FC<AdminUsersTableProps> = ({ filters }) => {
  const { navigate } = useNavigate();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { data, isFetching, isSuccess } =
    sdk.useListUsersWithFiltersAndPaginationUserListGetQuery(
      { ...pagination(page, pageSize), ...filters },
      { pollingInterval: 10_000 },
    );

  const rowTemplate = (user: UserResponse) => ({
    createdAt: user.created_at
      ? renderCellDate({ time: true, timestamp: user.created_at })
      : '-',
    email: user.email || '-',
    fio: `${user.surname} ${user.firstname} ${user.patronymic}`,
    id: user.id!,
    phone: user.phone || '-',
    role: user.role || '-',
    status: user.is_active ? (
      <CheckIcon color="var(--success-2)" />
    ) : (
      <CloseIcon color="var(--error-2)" />
    ),
  });
  const items = useMemo(
    () => (isSuccess ? data?.users || [] : []).map((item) => rowTemplate(item)),
    [data, isSuccess],
  );

  return (
    <div className="relative">
      <LoaderOverlay show={isFetching} />
      <TableController
        data={items}
        headers={headers}
        total={data?.edge.total}
        countItems={[10, 25, 50]}
        onChangePage={setPage}
        onChangePageSize={setPageSize}
        pageSize={pageSize}
        onClickRow={(e, item) =>
          navigate(RouterName.AdminUser, { id: item.id })
        }
        counterPagination
        notResetPage
      />
    </div>
  );
};
