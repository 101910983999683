import { useMemo } from 'react';

import { sdk } from '@shared/api';
import { formatAmount, getIsoDate, getPrice } from '@shared/libs/utils/format';
import { CalendarLabel } from '@shared/ui/Calendar/types';

export interface UsePricingHouseDatesProps {
  houseId: number;
  tariffId: number;
  discount?: number;
  activeInterval: [Date, Date] | null;
}
export const usePricingHouseCalendarDates = ({
  activeInterval,
  discount,
  houseId,
  tariffId,
}: UsePricingHouseDatesProps) => {
  const { data } =
    sdk.useGetHousePricingByHouseAndTariffOnDateRangeHousePricingGetRangeDatesGetQuery(
      {
        endDate: getIsoDate(activeInterval?.[1] || new Date()),
        houseId,
        startDate: getIsoDate(activeInterval?.[0] || new Date()),
        tariffId,
      },
      { skip: !activeInterval },
    );

  return useMemo<CalendarLabel[]>(
    () =>
      (data?.date_price_records || []).map((el) => ({
        date: new Date(el.date!),
        label: formatAmount(getPrice(el.price, discount)),
      })),
    [data],
  );
};
