import { createRefresh } from 'react-auth-kit';

import { history, router, RouterName } from '@app/router';
import { sdk } from '@shared/api';
import { isErrorResponse } from '@shared/libs/helpers';

import { RefreshTokenCallbackResponse } from 'react-auth-kit/dist/types';

export const useRefresh = () => {
  const [refresh] = sdk.useRefreshTokenAuthRefreshTokenPostMutation();

  return createRefresh({
    interval: 10,
    refreshApiCallback: async ({
      refreshToken,
    }): Promise<RefreshTokenCallbackResponse> => {
      const response = await refresh({
        refreshToken: {
          refresh_token: refreshToken!,
        },
      });

      if (isErrorResponse(response)) {
        history.navigate(router.urlFor(RouterName.Login));

        return {
          isSuccess: false,
          newAuthToken: '',
        };
      }
      const { data } = response;

      return {
        isSuccess: true,
        newAuthToken: data?.access_token,
        newRefreshToken: data?.access_token,
      };
    },
  });
};
