import { KeyboardEvent } from 'react';

export const handleNumberInput = (
  event: KeyboardEvent<HTMLInputElement>,
  decimal?: boolean,
) => {
  if (decimal) {
    const input = event.currentTarget.value;
    const isDecimalPoint = event.key === '.';

    if (!/\d/.test(event.key) && !isDecimalPoint) {
      event.preventDefault();
    }

    if (isDecimalPoint && input.includes('.')) {
      event.preventDefault();
    }
  } else {
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  }
};

export const handlePhoneInput = (event: KeyboardEvent<HTMLInputElement>) => {
  const input = event.currentTarget as HTMLInputElement;
  const value = input.value;

  if (event.key === 'Backspace') {
    return;
  }
  if (!/^[\d+]$/.test(event.key)) {
    event.preventDefault();
  }

  if (event.key === '+' && (value.length > 0 || value.includes('+'))) {
    event.preventDefault();
  }

  if (value.length >= 12) {
    event.preventDefault();
  }
};
