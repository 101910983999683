import { useEffect } from 'react';

import { sdk } from '@shared/api';

export const useMakePayment = (bookingId: number) => {
  const [makePayment, { data, error }] =
    sdk.useMakeNewPaymentPaymentPayPostMutation();

  const handleCreatePayment = () => {
    makePayment({ bookingId });
  };

  useEffect(() => {
    if (data) {
      window.open(data, '_blank');
    }
  }, [data]);

  return {
    data,
    error,
    handleCreatePayment,
  };
};
