import { object, string } from 'yup';

import { UserCreateWebValues } from '@entities/Auth';
import { BodyLoginAuthSignInPost } from '@shared/api';

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 30;
export const loginSchema = object().shape({
  password: string()
    .required(`Пароль обязательное поле`)
    .max(
      PASSWORD_MAX_LENGTH,
      `Максимальная длина пароля ${PASSWORD_MAX_LENGTH} символов`,
    )
    .min(
      PASSWORD_MIN_LENGTH,
      `Минимальная длина пароля ${PASSWORD_MIN_LENGTH} символов`,
    ),
  username: string().required(`Логин обязательное поле`),
});

export const authErrors: Record<
  number,
  Partial<Record<keyof BodyLoginAuthSignInPost, string>>
> = {
  201: {
    password: 'Доступ запрещён',
    username: ' ',
  },
  202: {
    password: 'Неправильное имя пользователя или пароль',
    username: ' ',
  },
  203: {
    password: 'Профиль пользователя не активирован',
    username: ' ',
  },
};

export const registerSchema = (password: string) =>
  object().shape({
    email: string()
      .required(`Поле email обязательно`)
      .email('Email неверного формата'),
    password: string()
      .required(`Пароль обязательное поле`)
      .max(
        PASSWORD_MAX_LENGTH,
        `Максимальная длина пароля ${PASSWORD_MAX_LENGTH} символов`,
      )
      .min(
        PASSWORD_MIN_LENGTH,
        `Минимальная длина пароля ${PASSWORD_MIN_LENGTH} символов`,
      ),
    password_confirm: string()
      .required(`Поле повторите пароль обязательно`)
      .oneOf([password], 'Пароли не совпадают'),
  });

export const registerErrors: Record<
  number,
  Partial<Record<keyof UserCreateWebValues, string>>
> = {
  104: {
    email: 'Указанный email уже используется для другого аккаунта',
  },
  105: {
    email: ' ',
    password: ' ',
    password_confirm: 'Пользователь с такими учетными данными уже существует',
  },
};

export const changePasswordSchema = (password: string) =>
  object().shape({
    password: string()
      .required(`Пароль обязательное поле`)
      .max(
        PASSWORD_MAX_LENGTH,
        `Максимальная длина пароля ${PASSWORD_MAX_LENGTH} символов`,
      )
      .min(
        PASSWORD_MIN_LENGTH,
        `Минимальная длина пароля ${PASSWORD_MIN_LENGTH} символов`,
      ),
    passwordConfirm: string()
      .required(`Поле повторите пароль обязательно`)
      .oneOf([password], 'Пароли не совпадают'),
  });

export const verifySchema = object().shape({
  firstname: string().required(`Имя обязательное поле`),
  patronymic: string().required(`Отчество обязательное поле`),
  phone: string()
    .matches(/^\+7\d{10}$/, 'Введите номер в формате +79999999999')
    .required(`Номер телефона обязательное поле`),
  surname: string().required(`Фамилия обязательное поле`),
});
