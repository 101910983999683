import { FC, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { ListItem } from '@/shared';
import { PasswordForm, VerificationForm } from '@entities/Auth';
import { useChangePassword, useIsVerify } from '@entities/Auth/libs/hooks';
import { useVerifyUser } from '@entities/Auth/libs/hooks/useVerifyUser';
import { LoaderOverlay, Spacer, Typography } from '@house-booking/ui-kit';
import { sdk } from '@shared/api';

import styles from './styles.module.scss';

export const UserProfile: FC = (): JSX.Element => {
  const { data: user, isLoading } = sdk.useMeUserMeGetQuery();
  const changePassword = useChangePassword();
  const [verifyUser, { isLoading: isLoadingVerify }] = useVerifyUser();
  const isVerify = useIsVerify();

  const formattedFio = useMemo(() => {
    if (!isVerify) {
      return '-';
    }

    return `${user?.surname} ${user?.firstname} ${user?.patronymic}`;
  }, [user]);

  return (
    <div className={cx(styles.profile, !isVerify && styles.profile_fullWidth)}>
      {isLoading ? (
        <LoaderOverlay show />
      ) : isVerify ? (
        <>
          <div className={styles.profile__info}>
            <ListItem
              label="Статус"
              value={user?.is_active ? 'Активный' : 'Не активный'}
            />
            <Spacer size={8} />
            <ListItem label="ФИО" value={formattedFio} />
            <Spacer size={8} />
            <ListItem label="Номер телефона" value={user?.phone || '-'} />
            <Spacer size={8} />
            <ListItem label="Email" value={user?.email || '-'} />
            <Spacer size={8} />
            <ListItem
              label="Дата регистрации"
              value={
                moment(user?.created_at).format('DD.MM.YYYY, HH:mm') || '-'
              }
            />
          </div>
          <div className={styles.profile__form}>
            <Typography
              tag="div"
              variant="head-16"
              color="on-surface-primary-1">
              Смена пароля
            </Typography>
            <Spacer size={4} />
            <PasswordForm onSubmit={changePassword} />
          </div>
        </>
      ) : (
        <div className={styles.profile__form}>
          <LoaderOverlay show={isLoadingVerify} />
          <Typography tag="div" variant="head-16" color="on-surface-primary-1">
            Верификация данных
          </Typography>
          <Spacer size={4} />
          <VerificationForm onSubmit={verifyUser} />
        </div>
      )}
    </div>
  );
};
