import moment from 'moment/moment';

import { TariffResponse } from '@shared/api';

export const formatAmount = (number: number, fixed?: boolean): string =>
  Intl.NumberFormat('ru-Ru').format(fixed ? +number.toFixed(0) : number);
export const getCoords = <
  T extends { latitude?: number; longtitude?: number; id?: number },
>(
  item: T,
): [number, number] => [item.latitude!, item.longtitude!];
export const getMinPrice = (items: TariffResponse[]): number => {
  let minPrice = items[0]?.base_price || 0;

  for (const el of items) {
    if (el.base_price && el.base_price < minPrice) {
      minPrice = el.base_price;
    }
  }

  return minPrice;
};

export const getIsoDate = (date: Date): string =>
  moment(date).format('YYYY-MM-DD');

export const pagination = (page: number, pageSize: number) => ({
  pagerOffset: (page - 1) * pageSize,
  pagerSize: pageSize,
});

export const getPrice = (base_price?: number, discount_pct?: number): number =>
  discount_pct ? base_price! * (1 - discount_pct) : base_price!;
