import { FC } from 'react';
import cx from 'classnames';

import { Container } from '@/shared';
import { useCheckPaymentBooking } from '@entities/Booking/libs/hooks';
import { BookingStatusEnum, sdk } from '@shared/api';
import { BookingHouseInfo, BookingMainInfo } from '@widgets/Booking';
import { HouseOperations } from '@widgets/House';

import styles from './styles.module.scss';

export interface HistoryDetailViewProps {
  params: {
    id: string;
  };
}
export const HistoryDetailView: FC<HistoryDetailViewProps> = ({
  params: { id },
}) => {
  useCheckPaymentBooking(+id);
  const { data } = sdk.useGetBookingByIdBookingGetIdGetQuery({
    id: +id,
  });
  const isActive =
    data?.status &&
    [
      BookingStatusEnum.Completed,
      BookingStatusEnum.Paid,
      BookingStatusEnum.CheckedIn,
    ].includes(data.status);

  return (
    <Container className={styles.historyDetail}>
      <div
        className={cx(
          styles.historyDetail__content,
          !isActive && styles.historyDetail__content_full,
        )}>
        <div className={styles.historyDetail__main}>
          <BookingHouseInfo bookingId={+id} />
          <BookingMainInfo bookingId={+id} />
        </div>
        {isActive && (
          <div>
            <HouseOperations bookingId={+id} />
          </div>
        )}
      </div>
    </Container>
  );
};
