import { useEffect, useState } from 'react';

import { Maybe } from '@house-booking/ui-kit';
import { BookingCreateFromApi, BookingResponse, sdk } from '@shared/api';
import { isFetchBaseQueryError } from '@shared/libs/helpers';

export type UseBookingHouseCreateAction = (
  params: BookingCreateFromApi,
) => void;
export interface UseBookingHouseCreateResult {
  error: Maybe<string>;
}
export interface UseBookingHouseCreateParams {
  onSuccess?: (data: BookingResponse) => void;
}
export const useBookingHouseCreate = (
  params?: UseBookingHouseCreateParams,
): [UseBookingHouseCreateAction, UseBookingHouseCreateResult] => {
  const [bookingHouse, { data, error: apiError }] =
    sdk.useCreateNewBookingBookingCreatePostMutation();
  const [error, setError] = useState<Maybe<string>>(null);

  const handleSubmit: UseBookingHouseCreateAction = (params) => {
    setError(null);
    bookingHouse({
      bookingCreateFromApi: params,
    });
  };

  useEffect(() => {
    if (data) {
      params?.onSuccess && params?.onSuccess(data);
    }
    if (apiError) {
      setError(
        isFetchBaseQueryError(apiError)
          ? apiError.data.error_details
          : 'Ошибка бронирования дома',
      );
    }
  }, [data, apiError, setError]);

  return [handleSubmit, { error }];
};
