import { FC } from 'react';

import { Card } from '@/shared';
import { Button, Spacer, Tooltip, Typography } from '@house-booking/ui-kit';
import { sdk, TariffResponse } from '@shared/api';
import { formatAmount } from '@shared/libs';
import { getPrice } from '@shared/libs/utils/format';

import styles from './styles.module.scss';

export interface HouseTariffsProps {
  tariffs: TariffResponse[];
  onBooking(tariff: TariffResponse): void;
}
export const HouseTariffs: FC<HouseTariffsProps> = ({ onBooking, tariffs }) => {
  const { data: user } = sdk.useMeUserMeGetQuery();

  return (
    <div className={styles.tariffs}>
      {tariffs.map((tariff) => (
        <Card key={tariff.id} className={styles.tariffs__card}>
          <div>
            <Typography variant="head-24" color="on-surface-primary-1">
              {tariff.tariff_name}
            </Typography>
            <Typography color="on-surface-primary-2" variant="head-16">
              {tariff.tariff_class}
            </Typography>
            <Spacer size={8} />
            <Typography color="on-surface-primary-1" variant="body-20">
              {tariff.description}
            </Typography>
          </div>
          <div className={styles.tariffs__cardRight}>
            {tariff?.discount_pct ? (
              <Typography variant="head-20">
                <Typography
                  className={styles.tariffs__oldPrice}
                  tag="span"
                  variant="head-16"
                  color="on-surface-primary-1">
                  {formatAmount(tariff.base_price!, true)} ₽
                </Typography>{' '}
                {formatAmount(
                  getPrice(tariff.base_price, tariff.discount_pct),
                  true,
                )}{' '}
                ₽{' '}
                <Typography tag="span" variant="head-16">
                  за день
                </Typography>
              </Typography>
            ) : (
              <Typography variant="head-20">
                {formatAmount(tariff.base_price!, true)} ₽{' '}
                <Typography tag="span" variant="head-16">
                  за день
                </Typography>
              </Typography>
            )}
            {user?.is_active && (
              <Tooltip
                disabled={tariff.is_active}
                label="Тариф не доступен"
                placement="top">
                <div>
                  <Button
                    size="large"
                    fullWidth
                    disabled={!tariff.is_active}
                    onClick={() => onBooking(tariff)}>
                    Забронировать
                  </Button>
                </div>
              </Tooltip>
            )}
          </div>
        </Card>
      ))}
    </div>
  );
};
